import React, { useEffect, useState, useMemo, useCallback } from "react";
import { usePageTitle } from "../../../hooks/useMeta";
import { useTranslation } from "react-i18next";
import ReactApexChart from "react-apexcharts";
import useApi from "../../../hooks/useApi";
import { subMonths, format } from "date-fns";
//import Button from "../../buttons/Button";
//import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
//import { faArrowRightLong } from "@fortawesome/pro-light-svg-icons";
//import useLangNavigate from "../../../hooks/useLangNavigate";
import { getAllOrders } from "../../../services/admin/orderService";
import { getRestaurantsCount } from "../../../services/admin/restaurantService";
import { getCompaniesCount } from "../../../services/admin/companyService";
import { getUsersCount } from "../../../services/admin/userService";
import { statusIds } from "../../../utils/constants";

export const AdminOverview = ({ tKey = "dashboard.admin." }) => {
  usePageTitle("adminOverview");
  const { t } = useTranslation();
  // const navigate = useLangNavigate();
  const getLast12Months = useCallback(() => {
    const months = [];
    for (let i = 11; i >= 0; i--) {
      months.push(format(subMonths(new Date(), i), "MMM yyyy"));
    }
    return months;
  }, []);
  const months = useMemo(() => getLast12Months(), [getLast12Months]);
  const [ordersChartSeries, setOrdersChartSeries] = useState([]);
  const [entitiesChartSeries, setEntitiesChartSeries] = useState([]);
  const [usersChartSeries, setUsersChartSeries] = useState([]);
  const [userChartLabels, setUserChartLabels] = useState([]);
  const [totalEntities, setTotalEntities] = useState(0);
  const { request: getAllOrdersRequest } = useApi(getAllOrders);
  const { request: getRestaurantsCountRequest } = useApi(getRestaurantsCount);
  const { request: getCompaniesCountRequest } = useApi(getCompaniesCount);
  const { request: getUsersCountRequest } = useApi(getUsersCount);

  const filterOrdersByMonth = useCallback((orders, monthString) => {
    return orders.filter((order) => {
      const orderMonth = format(new Date(order.startAt), "MMM yyyy");
      return orderMonth === monthString;
    });
  }, []);

  useEffect(() => {
    getAllOrdersRequest().then((response) => {
      const data = response.data;
      const series = [
        {
          name: t(`${tKey}orders.all`),
          data: months.map((month) => filterOrdersByMonth(data, month).length),
        },
        {
          name: t(`${tKey}orders.successful`),
          data: months.map(
            (month) =>
              filterOrdersByMonth(
                data.filter((order) =>
                  statusIds.SUCCESSFUL.includes(order.orderStatusId)
                ),
                month
              ).length
          ),
        },
        {
          name: t(`${tKey}orders.canceled`),
          data: months.map(
            (month) =>
              filterOrdersByMonth(
                data.filter((order) =>
                  statusIds.CANCELLED.includes(order.orderStatusId)
                ),
                month
              ).length
          ),
        },
        {
          name: t(`${tKey}orders.pending`),
          data: months.map(
            (month) =>
              filterOrdersByMonth(
                data.filter((order) =>
                  statusIds.PENDING.includes(order.orderStatusId)
                ),
                month
              ).length
          ),
        },
      ];
      setOrdersChartSeries(series);
    });
    Promise.all([
      getRestaurantsCountRequest(),
      getCompaniesCountRequest(),
      getUsersCountRequest(),
    ]).then((responses) => {
      const restaurantsCount = responses[0].data;
      const companiesCount = responses[1].data;
      setEntitiesChartSeries([restaurantsCount, companiesCount]);
      setTotalEntities(restaurantsCount + companiesCount);
      const userData = responses[2].data;
      const newUsers = userData.map((item) => item.new_users);
      const totalUsers = userData.map((item) => item.total_users);
      setUserChartLabels(
        userData.map((item) => format(new Date(item.month), "MMM yyyy"))
      );
      setUsersChartSeries([
        { name: t(`${tKey}users.new`), type: "column", data: newUsers },
        { name: t(`${tKey}users.total`), type: "line", data: totalUsers },
      ]);
    });
  }, [
    filterOrdersByMonth,
    getAllOrdersRequest,
    getCompaniesCountRequest,
    getRestaurantsCountRequest,
    getUsersCountRequest,
    months,
    t,
    tKey,
  ]);

  const ordersChartOptions = useMemo(
    () => ({
      chart: {
        id: "orders-bar",
        type: "bar",
        toolbar: {
          show: false,
        },
      },
      xaxis: {
        categories: months,
      },
      plotOptions: {
        bar: {
          horizontal: false,
        },
      },
      colors: ["#222222", "#1AE170", "#F2461D", "#FFC117"],
    }),
    [months]
  );

  const entitiesChartOptions = useMemo(
    () => ({
      chart: {
        type: "donut",
        toolbar: {
          show: false,
        },
      },
      labels: [
        t(`${tKey}entities.restaurants`),
        t(`${tKey}entities.companies`),
      ],
      colors: ["#1AE170", "#FFC117"],
      plotOptions: {
        pie: {
          donut: {
            labels: {
              show: true,
              total: {
                show: true,
                showAlways: true,
                label: "Total",
                formatter: function () {
                  return totalEntities;
                },
              },
            },
          },
        },
      },
      dataLabels: {
        enabled: true,
        formatter: function (val, opts) {
          return opts.w.config.series[opts.seriesIndex];
        },
        style: {
          fontSize: "16px",
          fontWeight: "bold",
          colors: ["white"],
        },
        dropShadow: {
          enabled: false,
        },
      },
      legend: {
        show: true,
        position: "bottom",
      },
      responsive: [
        {
          breakpoint: 480,
          options: {
            chart: {
              width: 200,
            },
            legend: {
              position: "bottom",
            },
          },
        },
      ],
    }),
    [t, tKey, totalEntities]
  );

  const usersChartOptions = useMemo(
    () => ({
      chart: {
        height: 350,
        type: "line",
        toolbar: {
          show: false,
        },
      },
      stroke: {
        width: [0, 4],
      },
      dataLabels: {
        enabled: true,
        enabledOnSeries: [1],
        style: {
          colors: ["#000"],
        },
      },
      labels: userChartLabels,
      xaxis: {
        type: "category",
        categories: userChartLabels,
        tickPlacement: "on",
      },
      yaxis: [
        {
          title: {
            text: t(`${tKey}users.new`),
            style: {
              color: "#FFC117",
            },
          },
          labels: {
            formatter: (val) => `${parseInt(val, 10)}`,
            style: {
              colors: "#FFC117",
            },
          },
        },
        {
          opposite: true,
          title: {
            text: t(`${tKey}users.total`),
            style: {
              color: "#00E396",
            },
          },
          labels: {
            formatter: (val) => `${parseInt(val, 10)}`,
            style: {
              colors: "#00E396",
            },
          },
        },
      ],
      tooltip: {
        x: {
          formatter: function (val) {
            return val;
          },
        },
        y: {
          formatter: function (val) {
            return parseInt(val, 10);
          },
        },
      },
      colors: ["#FFC117", "#00E396"],
      legend: {
        position: "top",
        horizontalAlign: "right",
        offsetX: -10,
      },
      responsive: [
        {
          breakpoint: 480,
          options: {
            legend: {
              position: "bottom",
            },
          },
        },
      ],
    }),
    [userChartLabels, t, tKey]
  );

  return (
    <div className="dashboard-content flex flex-wrap gap-2">
      <div className="col-7 bg-white rounded p-3 admin-overview-item">
        <h3 className="m-0">{t(`${tKey}users.title`)}</h3>
        <ReactApexChart
          type="bar"
          options={usersChartOptions}
          series={usersChartSeries}
          height={250}
        />
      </div>
      <div className="col-4 flex column bg-white rounded p-3 admin-overview-item">
        <h3 className="mt-0 mb-4">{t(`${tKey}entities.title`)}</h3>
        <ReactApexChart
          key={totalEntities}
          type="donut"
          options={entitiesChartOptions}
          series={entitiesChartSeries}
          height={250}
        />
      </div>
      <div className="col-12 flex column bg-white rounded p-3 admin-overview-item">
        <div className="p1">
          <h3 className="m-0">{t(`${tKey}orders.title`)}</h3>
          <ReactApexChart
            type="bar"
            options={ordersChartOptions}
            series={ordersChartSeries}
            height={300}
          />
        </div>
      </div>
    </div>
  );
};

export default AdminOverview;
