export const wsServicesMapping = {
  room: "8081",
  notifications: "8082",
  orders: "8083",
};

export const statusIds = {
  SUCCESSFUL: [5, 17, 18],
  CANCELLED: [6, 7, 8, 14],
  PENDING: [1, 2, 3, 4, 9, 10, 11, 12, 13, 15, 16],
};
