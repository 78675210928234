import React, { useEffect, useState, useMemo, useCallback } from "react";
import { Tooltip } from "react-tooltip";
import { useSelector } from "react-redux";
import { toast } from "react-toastify";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faPlus } from "@fortawesome/pro-light-svg-icons";
import {
  faGripDotsVertical,
  faEllipsisVertical,
  faInfoCircle,
} from "@fortawesome/pro-solid-svg-icons";
import Select from "react-select";
import { useTranslation } from "react-i18next";
import useApi from "../../../hooks/useApi";
import useModal from "../../../hooks/useModal";
import Button from "../../buttons/Button";
import DecoratedInput from "../../inputs/DecoratedInput";
import NoData from "../../errors/NoData";
import Spinner from "../../utils/Spinner";
import Table from "../../table/Table";
import DownloadTable from "../../table/DownloadTable";
import DropdownButton from "../../buttons/DropdownButton";
import Modal from "../../utils/Modal";
import {
  getDishes,
  getRelatedMenusAndTodaysSpecials,
  updateDishesOrder,
  duplicateDish,
  deleteDish,
  switchActivateDish,
} from "../../../services/dishService";
import { usePageTitle } from "../../../hooks/useMeta";
import {
  groupDishesByCategories,
  groupDishesByTypes,
} from "../../../utils/formatting";
import Collapse from "../../utils/Collapse";
import useLangNavigate from "../../../hooks/useLangNavigate";
import { formatSwissPrice } from "../../../utils/formatting";

export const RestaurantProducts = ({ tKey = "dashboard.products." }) => {
  usePageTitle("restaurantProducts");
  const { t } = useTranslation();
  const navigate = useLangNavigate();
  const { isModalOpen, openModal, closeModal } = useModal();
  const {
    error: getDishesError,
    loading: getDishesLoading,
    request: getDishesRequest,
  } = useApi(getDishes);
  const { request: deleteDishRequest } = useApi(deleteDish);
  const { request: duplicateDishRequest } = useApi(duplicateDish);
  const [query, setQuery] = useState("");
  const [selectedType, setSelectedType] = useState(null);
  const [selectedCategory, setSelectedCategory] = useState(null);
  const [selectedStatus, setSelectedStatus] = useState(null);
  const [uniqueCategories, setUniqueCategories] = useState([]);
  const [uniqueTypes, setUniqueTypes] = useState([]);
  const [selectedDish, setSelectedDish] = useState(null);
  const [dishes, setDishes] = useState([]);
  const [filteredDishes, setFilteredDishes] = useState([]);
  const [typesGroupedDishes, setTypesGroupedDishes] = useState({});
  const [categoriesGroupedDishes, setCategoriesGroupedDishes] = useState([]);
  const [modalContent, setModalContent] = useState(null);
  const restaurantId = useSelector((state) => state.user.restaurantId);

  const fetchRestaurantDishes = useCallback(
    async (restaurantId) => {
      await getDishesRequest(restaurantId)
        .then((response) => {
          setDishes(response.data);
          const categoryMap = new Map();
          const typeMap = new Map();
          response.data.forEach((dish) => {
            if (
              dish.dishcategory &&
              !categoryMap.has(dish.dishcategory.dishCategoryId)
            )
              categoryMap.set(
                dish.dishcategory.dishCategoryId,
                dish.dishcategory
              );
            if (dish.dishtype && !typeMap.has(dish.dishtype.dishTypeId))
              typeMap.set(dish.dishtype.dishTypeId, dish.dishtype);
          });
          setUniqueCategories(
            Array.from(categoryMap.values()).map((cat) => ({
              value: cat.dishCategoryId,
              label: cat.label,
            }))
          );
          setUniqueTypes(
            Array.from(typeMap.values())
              .sort((a, b) => a.order - b.order)
              .map((type) => ({
                value: type.dishTypeId,
                label: t(`utils.dishTypes.${type.label}`),
              }))
          );
        })
        .catch((error) => {
          console.log(error);
        });
    },
    [getDishesRequest, setDishes, setUniqueCategories, setUniqueTypes, t]
  );

  const handleDetail = useCallback(
    (row) => {
      navigate(`dashboard/products/${row.original.dishId}`);
    },
    [navigate]
  );

  const handleDuplicate = useCallback(
    async (dish) => {
      await duplicateDishRequest(dish)
        .then(() => {
          fetchRestaurantDishes(restaurantId);
          toast.success(t(`${tKey}duplicate.success`, { dishName: dish.name }));
        })
        .catch((err) => {
          console.error(err);
          toast.error(t(`${tKey}duplicate.error`, { dishName: dish.name }));
        });
    },
    [duplicateDishRequest, fetchRestaurantDishes, restaurantId, t, tKey]
  );

  const handleSwitchActiveDish = useCallback(
    async (event, dish) => {
      if (!dish.isPublished) return;
      event.stopPropagation();
      event.preventDefault();
      await switchActivateDish(dish.dishId)
        .then(() => {
          filteredDishes.find((d) => d.dishId === dish.dishId).isActive =
            !dish.isActive;
          setFilteredDishes([...filteredDishes]);
          typesGroupedDishes[dish.dishtype.label].find(
            (d) => d.dishId === dish.dishId
          ).isActive = !dish.isActive;
          setTypesGroupedDishes({ ...typesGroupedDishes });
          categoriesGroupedDishes[dish.dishcategory.label].find(
            (d) => d.dishId === dish.dishId
          ).isActive = !dish.isActive;
          setCategoriesGroupedDishes({ ...categoriesGroupedDishes });
          toast.success(
            t(`${tKey}status.${dish.isActive ? "deactivated" : "activated"}`, {
              dishName: dish.name,
            })
          );
        })
        .catch((err) => {
          console.error(err);
          toast.error(
            t(
              `${tKey}status.${
                dish.isActive ? "deactivate" : "activate"
              }.error`,
              {
                dishName: dish.name,
              }
            )
          );
        });
    },
    [
      filteredDishes,
      setFilteredDishes,
      categoriesGroupedDishes,
      setCategoriesGroupedDishes,
      typesGroupedDishes,
      setTypesGroupedDishes,
      t,
      tKey,
    ]
  );

  const handleDelete = useCallback(
    async (dish) => {
      setSelectedDish(dish);
      await getRelatedMenusAndTodaysSpecials(dish.dishId).then((res) => {
        const menus = res.data.menus;
        const todaysSpecials = res.data.todaysspecials;
        setModalContent(
          <>
            <h5 className="mt-0">{t(`${tKey}delete.title`)}</h5>
            <p>
              {t(
                `${tKey}delete.${
                  menus.length > 0 || todaysSpecials.length > 0
                    ? "textWithRelated"
                    : "text"
                }`,
                { dishName: dish.name }
              )}
            </p>
            {menus.length > 0 && (
              <div>
                {t(`${tKey}delete.menus`, { count: menus.length })}{" "}
                {menus.map((menu, index) => (
                  <span key={index}>
                    {menu.name}
                    {index < menus.length - 1 ? ", " : "."}
                  </span>
                ))}
              </div>
            )}
            {todaysSpecials.length > 0 && (
              <div>
                {t(`${tKey}delete.todaysSpecials`, {
                  count: todaysSpecials.length,
                })}{" "}
                {todaysSpecials.map((todaySpecial, index) => (
                  <span key={index}>
                    {todaySpecial.name}
                    {index < todaysSpecials.length - 1 ? ", " : "."}
                  </span>
                ))}
              </div>
            )}
          </>
        );
        openModal();
      });
    },
    [openModal, setSelectedDish, t, tKey]
  );

  const columns = useMemo(
    () => [
      {
        Header: (
          <div>
            <FontAwesomeIcon
              icon={faInfoCircle}
              data-tooltip-id="infoTip"
              data-tooltip-html={t(`${tKey}order.info`)}
            />
            <Tooltip id="infoTip" place="bottom" effect="solid" />
          </div>
        ),
        accessor: "order",
        isDragHandle: true,
        Cell: () => <FontAwesomeIcon color="black" icon={faGripDotsVertical} />,
        disableSortBy: true,
      },
      {
        Header: t(`${tKey}category.header`),
        accessor: (data) => (data.dishcategory ? data.dishcategory.label : ""),
        Cell: ({ value }) =>
          value ? value : t("utils.dishCategories.noCategory"),
        disableSortBy: true,
      },
      {
        Header: t(`${tKey}image`),
        accessor: "imagePath",
        Cell: ({ row }) =>
          row.original.imagePath ? (
            <img
              className="list-preview-img"
              src={row.original.imagePath}
              alt={row.original.name}
              height={50}
            />
          ) : (
            t(`utils.common.noImage`)
          ),
        disableSortBy: true,
      },
      {
        Header: t(`${tKey}title`),
        accessor: "name",
      },
      {
        Header: t(`${tKey}price`),
        accessor: "price",
        Cell: ({ row }) => {
          const { price, discount } = row.original;
          return discount ? (
            <>
              <div>
                {formatSwissPrice(price - (price * discount) / 100, true)}
              </div>
              <div className="strikethrough text-light p4 mt-1">
                {formatSwissPrice(price, true)}
              </div>
            </>
          ) : (
            price.toLocaleString("de-CH", {
              style: "currency",
              currency: "CHF",
            })
          );
        },
      },
      {
        Header: t(`${tKey}status.header`),
        Cell: ({ row }) => (
          <div
            onClick={(event) => {
              handleSwitchActiveDish(event, row.original);
            }}
            className={`rounded text-white py-1 px-3 text-center fit-content ${
              !row.original.isPublished
                ? "bg-dark"
                : row.original.isActive
                ? "bg-success"
                : "bg-danger"
            }`}
          >
            {t(
              `${tKey}status.${
                !row.original.isPublished
                  ? "unpublished"
                  : row.original.isActive
                  ? "active"
                  : "inactive"
              }`
            )}
          </div>
        ),
        disableSortBy: true,
      },
      {
        Header: "",
        id: "actions",
        Cell: ({ row }) => (
          <DropdownButton
            actions={[
              {
                label: t(`${tKey}actions.edit`),
                onClick: () => handleDetail(row),
              },
              {
                label: t(`${tKey}actions.duplicate`),
                onClick: () => handleDuplicate(row.original),
              },
              {
                label: t(`${tKey}actions.delete`),
                onClick: () => handleDelete(row.original),
              },
            ]}
          >
            <FontAwesomeIcon color="grey" icon={faEllipsisVertical} />
          </DropdownButton>
        ),
        disableSortBy: true,
      },
    ],
    [
      handleSwitchActiveDish,
      handleDelete,
      handleDetail,
      handleDuplicate,
      t,
      tKey,
    ]
  );

  const statusOptions = useMemo(
    () => [
      { value: "active", label: t(`${tKey}status.active`) },
      { value: "inactive", label: t(`${tKey}status.inactive`) },
      { value: "unpublished", label: t(`${tKey}status.unpublished`) },
    ],
    [t, tKey]
  );

  const goToAdd = () => {
    navigate("dashboard/products/add");
  };

  const handleOrderChange = async (newOrder) => {
    await updateDishesOrder(
      restaurantId,
      newOrder.map((dish) => dish.dishId)
    )
      .then(() => {
        fetchRestaurantDishes(restaurantId);
        toast.success(t(`${tKey}order.success`));
      })
      .catch((err) => {
        console.error(err);
        toast.error(t(`${tKey}order.error`));
      });
  };

  const deleteSelectedDish = async () => {
    await deleteDishRequest(selectedDish.dishId)
      .then((res) => {
        if (res.status === 200) {
          setDishes(dishes.filter((d) => d.dishId !== selectedDish.dishId));
          toast.success(
            t(`${tKey}delete.success`, { dishName: selectedDish.name })
          );
        }
      })
      .catch((err) => {
        console.error(err);
        toast.error(t(`${tKey}delete.error`, { dishName: selectedDish.name }));
      })
      .finally(async () => {
        setModalContent(null);
        setSelectedDish(null);
        closeModal();
        await fetchRestaurantDishes(restaurantId);
      });
  };

  const filterDishes = useCallback(
    (query) => {
      if (!dishes) return [];
      let filteredDishes = [...dishes];
      if (selectedCategory)
        filteredDishes = filteredDishes.filter(
          (d) => d.categoryId === selectedCategory.value
        );
      if (selectedType)
        filteredDishes = filteredDishes.filter(
          (d) => d.typeId === selectedType.value
        );
      if (selectedStatus)
        switch (selectedStatus.value) {
          case "active":
            filteredDishes = filteredDishes.filter(
              (d) => d.isPublished && d.isActive
            );
            break;
          case "inactive":
            filteredDishes = filteredDishes.filter(
              (d) => d.isPublished && !d.isActive
            );
            break;
          case "unpublished":
            filteredDishes = filteredDishes.filter((d) => !d.isPublished);
            break;
          default:
            break;
        }
      if (query)
        filteredDishes = filteredDishes.filter((d) =>
          d.name.toLowerCase().includes(query.toLowerCase())
        );
      return filteredDishes;
    },
    [dishes, selectedCategory, selectedType, selectedStatus]
  );

  useEffect(() => {
    fetchRestaurantDishes(restaurantId);
  }, [restaurantId, fetchRestaurantDishes]);

  useEffect(() => {
    const filteredDishes = filterDishes(query);
    setFilteredDishes(filteredDishes);
    setTypesGroupedDishes(
      groupDishesByTypes(filteredDishes.sort((a, b) => b.isActive - a.isActive))
    );
    setCategoriesGroupedDishes(
      groupDishesByCategories(
        filteredDishes.sort((a, b) => b.isActive - a.isActive)
      )
    );
  }, [
    query,
    dishes,
    selectedType,
    selectedCategory,
    selectedType,
    selectedStatus,
    filterDishes,
  ]);

  if (getDishesError) return <div>Error: {getDishesError}</div>;
  if (getDishesLoading) return <Spinner />;

  return (
    <div className="dashboard-content">
      <div className="flex gap-1">
        <Button
          className="add-btn"
          variant="primary"
          size="sm"
          onClick={goToAdd}
        >
          <FontAwesomeIcon icon={faPlus} />
          <span className="ml-2">{t(`${tKey}addProduct`)}</span>
        </Button>
        <div className="ml-auto flex gap-1 w-80 search-module">
          <DecoratedInput
            className="bg-white dashboard-search-bar w-100"
            input={{
              id: "searchProduct",
              class: "p4",
              value: "",
              placeholder: t(`${tKey}searchPlaceholder`, {
                count: dishes ? dishes.length : 0,
              }),
            }}
            hasResearchButton
            onResearchButtonClick={(query) => {
              setQuery(query);
            }}
          />
          <Select
            className="react-custom-select dashboard-select"
            classNamePrefix="react-select"
            placeholder={t(`${tKey}category.header`)}
            options={uniqueCategories}
            value={selectedCategory}
            isClearable
            isSearchable={false}
            getOptionLabel={(option) => option.label}
            getOptionValue={(option) => option.value}
            onChange={(value) => setSelectedCategory(value)}
          />
          <Select
            className="react-custom-select dashboard-select"
            classNamePrefix="react-select"
            placeholder={t(`${tKey}type.header`)}
            options={uniqueTypes}
            value={selectedType}
            isClearable
            isSearchable={false}
            getOptionLabel={(option) => option.label}
            getOptionValue={(option) => option.value}
            onChange={(value) => setSelectedType(value)}
          />
          <Select
            className="react-custom-select dashboard-select"
            classNamePrefix="react-select"
            placeholder={t(`${tKey}status.header`)}
            options={statusOptions}
            value={selectedStatus}
            isClearable
            isSearchable={false}
            getOptionLabel={(option) => option.label}
            getOptionValue={(option) => option.value}
            onChange={(value) => setSelectedStatus(value)}
          />
          <DownloadTable
            data={filteredDishes}
            fileName="dishes"
            fileTypes={["csv", "xlsx"]}
          />
        </div>
      </div>
      <div className="mt-4 table-container">
        {filteredDishes?.length === 0 ? (
          <NoData />
        ) : selectedType ? (
          Object.keys(categoriesGroupedDishes).map((key, index) => (
            <Collapse
              key={key}
              className="mb-2 rounded bg-white"
              title={key}
              variant="pastel-medium"
              initialStateOpen
            >
              <Table
                key={index}
                columns={columns}
                data={categoriesGroupedDishes[key]}
                isDroppable
                onClickRow={handleDetail}
                onOrderChange={handleOrderChange}
              />
            </Collapse>
          ))
        ) : (
          Object.keys(typesGroupedDishes).map((key, index) => (
            <Collapse
              key={key}
              className="mb-2 rounded bg-white"
              title={t(`utils.dishTypes.${key}`)}
              variant="pastel-medium"
            >
              <Table
                key={index}
                columns={columns}
                data={typesGroupedDishes[key]}
                onClickRow={handleDetail}
              />
            </Collapse>
          ))
        )}
      </div>
      <Modal
        isOpen={isModalOpen}
        onClose={closeModal}
        onOK={deleteSelectedDish}
      >
        {modalContent}
      </Modal>
    </div>
  );
};

export default RestaurantProducts;
