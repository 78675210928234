import axios from "axios";
import { getEnvVariable } from "../utils/config";
import L from "leaflet";

export const getLatLongFromAddress = async (address, npa = "", city = "") => {
  const fullAddress = `${address} ${npa} ${city} Switzerland`;
  try {
    const coordinateResponse = await axios.get(
      `https://geocode.maps.co/search?q=${fullAddress}&api_key=${getEnvVariable(
        "REACT_APP_MAPS_API_KEY"
      )}`
    );
    if (!coordinateResponse.data.length) return { latitude: 0, longitude: 0 };
    const latitude = coordinateResponse.data[0].lat;
    const longitude = coordinateResponse.data[0].lon;
    return { latitude, longitude };
  } catch (error) {
    console.error(error);
  }
};

export const createRestaurantIcon = () => {
  return new L.Icon({
    iconUrl:
      "https://raw.githubusercontent.com/pointhi/leaflet-color-markers/master/img/marker-icon-2x-red.png",
    iconSize: [25, 41],
    iconAnchor: [12, 41],
    popupAnchor: [1, -34],
  });
};

export const createCompanyIcon = () => {
  return new L.Icon({
    iconUrl:
      "https://raw.githubusercontent.com/pointhi/leaflet-color-markers/master/img/marker-icon-2x-blue.png",
    iconSize: [25, 41],
    iconAnchor: [12, 41],
    popupAnchor: [1, -34],
  });
};
